.body {
  background: radial-gradient(
      1057.13% 322.92% at 19.83% 116.1%,
      rgba(72, 188, 242, 0.3) 0%,
      rgba(2, 41, 136, 0.3) 41.67%,
      rgba(0, 0, 38, 0.3) 78.13%
    ),
    linear-gradient(
      228.21deg,
      #00123f 7.56%,
      #001a4e 24.18%,
      #00235d 53.68%,
      #002e6d 78.4%
    );
  padding: 75px 25px 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.text-coming-soon {
  font-weight: 500;
  font-size: 27px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5em;
  color: #c6e3f7;
}
.image-store {
  width: 170px;
  margin-top: 20px;
}
.store-btn {
  margin-right: 15px;
  cursor: pointer;
}
.div-store {
  margin-top: 25px;
  text-align: center;
  justify-content: center;
}
.title {
  font-weight: 500;
  font-size: 50px;
  line-height: 61px;
  text-align: center;

  font-family: "EuclidCircularASemiBold";
  color: #ffffff;
}

@media (max-width: 520px) {
  .image-mockup-desktop {
    display: none;
  }
  .image-mockup-mobile {
    display: block;
    width: 100%;
    margin-bottom: -4px;
  }
}
@media (min-width: 520px) {
  .image-mockup-desktop {
    display: block;
    width: 400px;
    margin-bottom: -4px;
  }
  .image-mockup-mobile {
    display: none;
    width: 100%;
    margin-bottom: -4px;
  }
}

/* Contact Form Styles */
.contact-form-container {
  width: 50%;
  min-width: 320px;
  max-width: 500px;
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 32px 40px;
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(20px);
  border-radius: 28px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-title {
  font-family: "EuclidCircularASemiBold";
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 8px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-group {
  width: 100%;
  margin: 0;
}

.form-input {
  width: 100%;
  padding: 16px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  color: #ffffff;
  font-size: 15px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.form-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.form-input:focus {
  outline: none;
  border-color: rgba(75, 110, 255, 0.5);
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 4px rgba(75, 110, 255, 0.1);
}

.form-textarea {
  min-height: 150px;
  max-height: 150px;
  height: 150px;
  resize: none;
  line-height: 1.5;
}

.privacy-checkbox {
  margin: 8px 0;
  color: #fff;
  font-size: 14px;
}

.privacy-checkbox label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 4px 0;
}

.privacy-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 4px;
  accent-color: #4b6eff;
}

.privacy-checkbox span {
  opacity: 0.9;
  font-size: 13px;
  line-height: 1.4;
}

.privacy-checkbox a {
  color: #4b6eff;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 500;
}

.privacy-checkbox a:hover {
  color: #6b4bff;
  text-decoration: underline;
}

.submit-button {
  background: linear-gradient(135deg, #4b6eff 0%, #6b4bff 100%);
  border: none;
  padding: 16px 32px;
  border-radius: 14px;
  color: white;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(75, 110, 255, 0.3);
  background: linear-gradient(135deg, #5577ff 0%, #7655ff 100%);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none !important;
  box-shadow: none !important;
}

.form-message {
  padding: 14px 18px;
  border-radius: 12px;
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
  line-height: 1.4;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-message.success {
  background: rgba(46, 213, 115, 0.15);
  color: #2ed573;
  border: 1px solid rgba(46, 213, 115, 0.2);
}

.form-message.error {
  background: rgba(255, 71, 87, 0.15);
  color: #ff4757;
  border: 1px solid rgba(255, 71, 87, 0.2);
}

@media (max-width: 520px) {
  .contact-form-container {
    width: 85%;
    min-width: unset;
    margin: 15px auto 40px;
    padding: 24px 20px;
    border-radius: 20px;
  }

  .contact-title {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 4px;
  }

  .contact-form {
    gap: 16px;
  }

  .form-input {
    padding: 14px;
    font-size: 14px;
    border-radius: 12px;
  }

  .form-input::placeholder {
    font-size: 13px;
  }

  .form-textarea {
    min-height: 120px;
    max-height: 120px;
    height: 120px;
  }

  .privacy-checkbox {
    margin: 4px 0;
  }

  .privacy-checkbox label {
    gap: 8px;
  }

  .privacy-checkbox input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }

  .privacy-checkbox span {
    font-size: 12px;
  }

  .submit-button {
    padding: 14px 24px;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 12px;
  }

  .form-message {
    padding: 12px 16px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 13px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.2) inset;
  background-color: rgba(0, 0, 0, 0.2) !important;
  transition: background-color 0s 50000s;
}

