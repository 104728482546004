.header {
  padding: 20px;
  background: white;
  display: flex;
  justify-content: center;
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}
.nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container div {
  flex: 1;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 6px;
  transition: all 0.2s ease;
  line-height: 1;
}

.nav-link:hover {
  background-color: #f5f5f5;
  color: #0066cc;
}
